import platform from '@propertypal/shared/src/utils/platform';
import Head from 'next/head';
import { GetServerSideProps } from 'next/types';
import React from 'react';
import generateMetaTags from '../components/layout/MetaTags';
import Login from '../components/login/Login';
import { LoginComponentProps } from '../types/LoginComponentProps';
import { requireNonAuth } from '../utils/auth';

const metaData = {
  title: `My ${platform.title}`,
  // eslint-disable-next-line max-len
  description: `Login To ${platform.title}'s User Section To Access Exclusive Features And Take Your House Hunting To The Next Level.`,
  url: `/login`,
};

export const getServerSideProps: GetServerSideProps = requireNonAuth;

const LoginPage = (props: LoginComponentProps) => {
  return (
    <>
      <Head>{generateMetaTags(metaData)}</Head>

      <Login initialTab="login" {...props} />
    </>
  );
};

export default LoginPage;
